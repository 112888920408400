<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆维修</el-breadcrumb-item>
      <el-breadcrumb-item>维修单位</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <div class="top">
        <el-button type="primary" @click="opentan()">添加信息</el-button>
      </div>

      <!-- 添加弹窗 -->   
      <el-dialog title="新增维修单位信息" :visible.sync="dialogVisible" width="40%">
        <el-form ref="dform" :model="dform" label-width="100px" :rules="rules">
          <el-form-item label="单位名称" label-width="150px" prop="maintenance_company">
            <div class="inp"><el-input type="text" v-model="dform.maintenance_company" placeholder="单位名称"></el-input></div>
          </el-form-item>
          <el-form-item label="状态" label-width="150px" >
            <el-select v-model="dform.status" placeholder="未选择">
              <el-option v-for="item in typelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select> 
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelfun()">取 消</el-button>
          <el-button type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确定</el-button>
        </span>
      </el-dialog>

      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="maintenance_company" label="公司名"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row)"></el-button>

            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { Settian, Getlist, Setlist, Endlist } from '../../../api/car'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      // 弹窗按钮的状态
      tantype: 'add',
      // 列表数据
      tableData: [],
      dialogVisible: false,
      dform: {
        maintenance_company: '',
        status: ''
      },
      typelist: [
        { name: '禁用', id: 0 },
        { name: '启用', id: 1 }
      ],
      rules: {
        maintenance_company: [
          { required: true, message: '单位名称', trigger: 'blur' },
          { min: 2, message: '请填写单位名称', trigger: 'blur' }
        ],
        status: [{ required: true, message: '请选择客户类型', trigger: 'change' }]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      }
    }
  },
  mounted() {
    this.Getlists()
    this.dform.status = this.typelist[1].id
  },
  methods: {
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getlists()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getlists()
    },
    // 编辑
    editfun(ev) {
      console.log(ev)
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    // 删除
    delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Endlists(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
    },
    // 添加信息取消
    cancelfun(ruleFrom) {
      //   this.dialogVisible = false
      this.handleClose()
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.ClientInformations(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editcompanyfun(this.dform)
            }
          })
          console.log(type)
          break
      }
    },
    handleClose() {
      this.dform.maintenance_company = ''
      this.dialogVisible = false
      this.tantype = 'add'
    },

    /////////////请求区//////////////
    // 添加维修单位
    async ClientInformations(v) {
      const { data } = await Settian(v)
      this.getanpostfun(data, '添加', this.Getlists())
    },
    // 修改维修单位
    async editcompanyfun(v) {
      const { data } = await Setlist(v)
      this.getanpostfun(data, '修改', this.Getlists())
    },
    async Endlists(id) {
      const { data } = await Endlist({ id })
      this.getanpostfun(data, '删除', this.Getlists())
    },
    // 拉取单位列表
    async Getlists() {
      const { data } = await Getlist({ ...this.pageData })
      console.log(data)
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style scoped>
.inp {
  width: 220px;
}
</style>